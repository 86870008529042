.custom-background-publicformpreview {
  padding: 20px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-public-form-preview-publicformpreview {
  max-width: 900px;
  width: 100%;
  padding: 40px;
  padding-top: 20px;
  border-radius: 8px;
  background-color: #ffffff;
}

.header-publicformpreview {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.logo-publicformpreview {
  width: 80px;
  height: auto;
  margin-right: 20px;
}

.header-text-publicformpreview {
  display: flex;
  flex-direction: column;
}

.company-title-publicformpreview {
  font-size: 20px;
  color: #333;
  margin: 0;
}

.company-subtitle-publicformpreview {
  font-size: 16px;
  color: #666;
  margin: 0;
}

.custom-form-title-publicformpreview {
  font-size: 28px;
  color: #333;
  font-weight: 550;
  margin-bottom: 30px;
  font-size: larger;
  margin-top: 5px;
}

.custom-form-publicformpreview {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: -20px;
}

.custom-form-row-publicformpreview {
  display: flex;
  flex-direction: column;
}

/* Adjust the width of the form group for top-to-bottom layout */
.custom-form-group-publicformpreview {
  width: 100%;
}

/* Add specific spacing below the Contact Number field */
.custom-form-group-publicformpreview input[type="tel"] {
  margin-bottom: 40px; /* Adjust this value as needed */
}

/* Add spacing below Contact Number when using the PhoneInput library */
.react-tel-input {
  margin-bottom: 20px;
}

/* Container to ensure input directly below the label*/
.custom-form-input-container-publicformpreview {
  margin-top: 5px;
}
.custom-form-label-publicformpreview {
  margin-bottom: 8px;
  font-size: 16px;
  color: #333;
  font-weight: 500;
  display: inline-block;
  font-family: "Inter", sans-serif;
}

.required-publicformpreview {
  color: red;
  margin-left: 5px;
}

.custom-note-container-publicformpreview {
  margin-top: 10px; /* Add spacing above the ReactQuill input */
  margin-bottom: 20px; /* Add spacing below the ReactQuill input */
}

.custom-form-input-publicformpreview {
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  transition: border-color 0.3s ease;
  background-color: #f9f9f9;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
}

.custom-form-input-publicformpreview,
.checkbox-group-publicformpreview label,
.radio-group-publicformpreview label,
textarea {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: #666666;
}
.custom-form-input-publicformpreview:focus {
  border-color: #007bff;
  outline: none;
}

.custom-form-submit-button-publicformpreview,
.custom-form-clear-button-publicformpreview {
  padding: 15px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 150px;
}

form.custom-form-publicformpreview
  .custom-form-submit-button-publicformpreviewfinalsave,
form.custom-form-publicformpreview .custom-form-save-draft-button {
  width: 13%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 13px;
  box-sizing: border-box;
  width: 135px;
}

form.custom-form-publicformpreview
  .custom-form-submit-button-publicformpreviewfinalsave:hover,
form.custom-form-publicformpreview .custom-form-save-draft-button:hover {
  background-color: #005ec2;
}

.custom-form-save-draft-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 13px;
  box-sizing: border-box;
}

.custom-form-save-draft-button:hover {
  background-color: #0056b3;
}

.custom-form-clear-button-publicformpreview {
  background-color: #ffffff;
  color: #007bff;
  border: 1px solid #007bff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 13px;
  box-sizing: border-box;
  padding: 10px;
  width: auto;
}

.custom-form-clear-button-publicformpreview:hover {
  background-color: #e6f7ff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.form-buttons-publicformpreview {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.custom-form-input-publicformpreview.error-publicformpreview {
  border-color: red;
}

.error-message-publicformpreview {
  color: red;
  font-size: 12px;
  margin-bottom: 14px;

  display: flex;
  align-items: center;
}

.error-icon-publicformpreview {
  margin-right: 5px;
  color: white;
  background-color: red;
  border-radius: 50%;
  padding: 1px;
  width: 15px;
  height: 15px;
}

.character-limit-publicformpreview {
  text-align: right;
  font-size: 10px;
  color: #555;
  margin-top: -15px;
}

.checkbox-group-publicformpreview {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.option-label-publicformpreview {
  font-weight: 100;
}

.radio-group-publicformpreview {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  margin-top: 1px;
}

.option-label-publicformpreview-radio {
  font-weight: 100;
  font-size: 14px;
}

/* Thank you message styling */
.thank-you-message-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e5f4fd;
  z-index: 1000;
}

.thank-you-message-publicformpreview {
  text-align: center;
  margin-top: 50px;
  padding: 20px;
  background-color: #b5e2fc;
  border-radius: 8px;
  box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.3);
}

.icon-container-publicformpreview {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 67px;
  height: 67px;
  background: #eff6ff;
  border-radius: 50%;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.icon-container-publicformpreview svg {
  width: 48px;
  height: 48px;
}

.thank-you-message-publicformpreview h2 {
  color: #333;
  font-size: 20px;
  font-weight: 500;
  padding: 30px;
}

.response-page-publicformpreview {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e5f4fd;
  z-index: 1000;
}

.response-message-container-publicformpreview {
  background-color: #c9f3ff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.response-title-publicformpreview {
  font-size: 28px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
}

.response-text-publicformpreview {
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
}
/* START: Add styles to ensure the "Contact Number" input matches the size of other inputs */
.phone-input-formpreview .custom-form-inputformpreview,
.phone-input-publicformpreview .custom-form-input-publicformpreview {
  width: 100% !important; /* Make the width of the phone input 100% */
  padding: 10px; /* Add padding to match other input fields */
  border: 1px solid #ccc; /* Add a border to match other input fields */
  border-radius: 4px; /* Add border radius to match other input fields */
  font-size: 14px; /* Set the font size to match other input fields */
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
  height: auto;
  width: auto;
}

.react-tel-input .form-control {
  width: 401px;
  height: 42px;
  background-color: #f9f9f9;
  width: 100% !important;
}
.copyright-publicformpreview {
  text-align: center;
  margin-top: 50px;
  font-size: 11px;
  color: #888;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}
.number-container-publicformpreview {
  display: inline-flex;
}

.number-circle-publicformpreview {
  display: inline-block;
  background-color: white;
  color: #007bff;
  width: 30px;
  height: 20px;
  border-radius: 25%;
  border: 1px solid #007bff;
  text-align: center;
  line-height: 18px;
  font-size: 12px;
  margin-right: 10px;
  font-family: "Inter", sans-serif;
}

/* Fix for conditional label not inheriting number style */
.conditionally-displayed-field {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
}
/* button changes to "Submitting..." or "Saving..." */
.custom-form-submit-button-publicformpreviewfinalsave,
.custom-form-save-draft-button {
  position: relative;
  overflow: hidden;
  color: white;
  transition: background-color 0.3s ease;
}

.custom-form-submit-button-publicformpreviewfinalsave[disabled],
.custom-form-save-draft-button[disabled] {
  background-color: #0056b3;
  cursor: not-allowed;
  opacity: 0.8;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* button style during "Submitting..." or "Saving..."  */
.custom-form-submit-button-publicformpreviewfinalsave[disabled]::before,
.custom-form-save-draft-button[disabled]::before {
  content: "";
  position: absolute;
  cursor: not-allowed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(173, 216, 230, 0.6) 25%,
    rgba(70, 130, 180, 0.7) 50%,
    rgba(173, 216, 230, 0.6) 75%
  );
  background-size: 200% 100%;
  animation: loading-progress 1.5s infinite linear;
}

.custom-form-submit-button-publicformpreviewfinalsave:hover,
.custom-form-save-draft-button:hover {
  background-color: #004080;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

@keyframes loading-progress {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.submitting-message-container-publicformpreview {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
}

.submitting-message-publicformpreview {
  color: red;
  font-size: 14px;
  text-align: center;
  background-color: #ffe6e6;
  padding: 5px 10px;
  border-radius: 5px;
  width: fit-content;
}

@media (max-width: 768px) {
  .submitting-message-publicformpreview {
    font-size: 13px;
    padding: 4px 8px;
  }
}

@media (max-width: 480px) {
  .submitting-message-publicformpreview {
    font-size: 12px;
    padding: 3px 6px;
    max-width: 90%;
  }
}

.form-buttons-publicformpreview {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 10px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .custom-form-submit-button-publicformpreviewfinalsave,
  .custom-form-clear-button-publicformpreview {
    width: 100% !important;
  }
}
@media (max-width: 425px) {
  .form-buttons-publicformpreview {
    flex-direction: column;
    align-items: center;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .custom-form-submit-button-publicformpreviewfinalsave,
  .custom-form-clear-button-publicformpreview {
    width: 135px !important;
  }
}
