

.dashboard-homepage-allapplications {
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.logo-container-homepage-allapplications {
  margin-bottom: 20px;
  width: 100px;
  height: 100px;
  margin-left: 70px;
  margin-top: 16px;
}

.logo-homepage-allapplications img {
  width: 100%;
}

.nav-container-homepage-allapplications {
  flex-grow: 1;
}

.nav-homepage-allapplications ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-homepage-allapplications ul li {
  padding: 15px 0;
  cursor: pointer;
  font-size: 16px;
  color: #6d6d6d;
  font-weight: 600;
}

.nav-homepage-allapplications ul li a {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  width: 100%;
}

.nav-homepage-allapplications ul li.active,
.nav-homepage-allapplications ul li:hover {
  background-color: #f5faff;
  color: #0183ff;
}

.nav-homepage-allapplications ul li .nav-icon-homepage-allapplications {
  margin-right: 10px;
}

.main-content-homepage-allapplications {
  margin-top: 70px; /* Height of the fixed header */
  margin-left: 250px; /* Width of the fixed sidebar */
  overflow-y: scroll; /* For scroller Firefox */
  scrollbar-width: thin; /*width of the scrollbar */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  padding: 20px;
}

.header-homepage-allapplications {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  height: 70px;
}

.founder-homepage-allapplications {
  font-size: 24px;
  font-weight: 700;
}

.profile-section-homepage-allapplications {
  display: flex;
  align-items: center;
}

.user-info-homepage-allapplications {
  display: flex;
  align-items: center;
}

.user-initials-homepage-allapplications {
  color: #fff;
  padding: 5px;
  border: 1px solid #eaeaea;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-size: 18px;
}

.user-details-homepage-allapplications {
  text-align: left;
}

.logout-button-homepage-allapplications {
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 8px 5px;
  border-radius: 4px;
}

.user-name-homepage-allapplications {
  font-weight: bold;
  font-size: 14px;
}

.user-email-homepage-allapplications {
  color: #666;
  font-size: 12px;
}

.content-homepage-allapplications {
  padding: 5px;
}

.title-rounds-container {
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 300;
  color: #4f4f4f;
  font-family: "Inter", sans-serif;
}

.applications-table-allapplications table {
  width: 100%;
  border-collapse: collapse;
}

.applications-table-allapplications th,
.applications-table-allapplications td {
  padding: 5px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.applications-table-allapplications th {
  background-color: #f4f4f4;
  font-size: 15px;
  font-weight: 600;
  color: #404040;
  font-family: "Inter", sans-serif;
}
.applications-table-allapplications td {
  font-size: 14px;
  font-weight: 300;
  color: #404040;
  font-family: "Inter", sans-serif;
}

.applications-table-allapplications tr:hover {
  background-color: #f1f8ff;
}

.applications-table-allapplications tbody input[type="checkbox"] {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  cursor: pointer;
  margin-left: 5px;
}

.applications-table-allapplications tbody input[type="checkbox"]:checked {
  background-color: #d0d0d0;
}

.applications-table-allapplications tbody tr:nth-child(odd) {
  background-color: #ffffff;
}

.applications-table-allapplications tbody tr:nth-child(even) {
  background-color: #f3f3f3;
}

.applications-table-allapplications tbody tr:hover {
  background-color: #edf6ff;
}

.custom-response-header-allapplications {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #fff;
  padding: 6px;
  z-index: 1000;
}

.custom-button-export-allapplications {
  color: white;
  background-color: #0183ff;
  border: 1px solid #0183ff;
  border-radius: 5px;
  padding: 5px 7px;
  cursor: pointer;
  font-size: 14px;
}

.custom-button-export-allapplications:hover {
  color: #007bff;
  background-color: #fff;
  border: 1px solid #0183ff;
  border-radius: 5px;
  padding: 5px 7px;
  cursor: pointer;
  font-size: 14px;
}

.below-tab-button-applications {
  background-color: #ffffff;
  color: #007bff;
  padding: 5px 7px;
  border: 1px solid #82befe;
  cursor: pointer;
  font-size: 13px;
  font-weight: 300;
  border-radius: 4px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 8px;
  margin-bottom: 2px;
  font-family: "Inter", sans-serif;
}

.below-tab-button-applications:hover {
  background-color: #ffffff;
  color: #0056b3;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.15);
}

.below-tab-button-applications:active {
  background-color: white !important;
  color: blue !important;
}

.pipeline-tabs-allapplications {
  display: flex;
  border-bottom: 1px solid #ddd;
  margin-bottom: 1px;
  margin-top: 1px;
  font-family: "Inter", sans-serif;
}

.tab-button-allapplications {
  background: none;
  border: none;
  font-size: 14px;
  padding: 4px 12px;
  cursor: pointer;
  color: #6d6d6d;
  position: relative;
  font-family: "Inter", sans-serif;
  border-radius: 3px;
}

.tab-button-allapplications.active {
  color: #6d6d6d;
  font-weight: 600;
}

.tab-button-allapplications.active::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #007bff;
  position: absolute;
  bottom: -0.1px;
  left: 0;
}

.tab-button-allapplications:hover {
  color: #000000;
  background-color: transparent;
  border-radius: 1px;
}

.sorticon-applications {
  color: #007bff;
  cursor: pointer;
  text-decoration: none;
  margin-left: 5px;
}
.sorticon-applications:hover {
  color: #000000;
  cursor: pointer;
}

.view-details-button-applications {
  background-color: transparent;
  color: #007bff;
  border: none;
  cursor: pointer;
  font-size: 13px;
  font-weight: 300;
  font-family: "Inter", sans-serif;
}

.view-details-button-applications:hover {
  font-weight: 300;
  color: #404040;
  background-color: transparent;
}

.response-details-applications {
  margin-top: 1px;
  background-color: #f7f7f7;
}
.custom-response-details-applications {
  margin-bottom: 20px;
}

.header-with-button-applications {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #fff8f8;
  z-index: 1000;
  padding: 0px 10px 5px 10px;
}

.button-back-to-applications {
  color: white;
  background-color: #0183ff;
  border: 1px solid #0183ff;
  border-radius: 5px;
  padding: 5px 7px;
  cursor: pointer;
  font-size: 16px;
}

.button-back-to-applications:hover {
  color: #007bff;
  background-color: #fff;
  border: 1px solid #0183ff;
  border-radius: 5px;
  padding: 5px 7px;
  cursor: pointer;
  font-size: 16px;
}

.response-item-applications {
  display: flex;
  margin-bottom: 10px;
}

.response-key-top-allapplications {
  margin-left: 10px;
}
.number-box-allapplications {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 1px solid #007bff;
  color: #007bff;
  border-radius: 5px;
  font-size: small;
  font-family: "Inter", sans-serif;
}
.response-key-allapplications {
  font-size: 14px;
  font-weight: 500;
  color: #787878;
  font-family: "Inter", sans-serif;
}

.response-key-document-allapplications {
  margin-left: 5px;
  gap: 55px;
  font-size: 14px;
  font-weight: 500;
  color: #787878;
  font-family: "Inter", sans-serif;
}

.response-value-allapplications {
  margin-left: 15px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #454545;
  font-family: "Inter", sans-serif;
}
.no-response-allapplications {
  color: #535353;
  margin-top: 20px;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
}

.response-value-file-allapplications {
  font-size: 14px;
  color: #454545;
  margin-right: 5px;
  display: block;
  margin-bottom: 1px;
  font-family: "Inter", sans-serif;
}

.documents-section-applications {
  margin-top: 20px;
  margin-bottom: 30px;
}

.documents-heading-applications {
  color: #020202;
  font-size: 23px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.file-item-applications {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.file-response-applications {
  cursor: pointer;
  color: #007bff;
  text-decoration: none;
}

.file-response-applications:hover {
  color: rgb(0, 0, 0);
}

.link-response-applications {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s;
}

.link-response-applications:hover {
  color: black;
}

.pagination-container-applications {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.pagination-applications {
  display: flex;
  align-items: center;
}

.pagination-arrow-applications {
  cursor: pointer;
  margin: 0 10px;
  background-color: transparent;
  color: #0183ff;
  border: none;
  padding: 5px 10px;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.pagination-arrow-applications:hover {
  background-color: #b3e0ff;
  color: #909090;
  border: 0.5px solid rgb(205, 205, 205);
}

.pagination-arrow-applications.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  background-color: transparent;
  color: #ccc;
}

.page-number-applications {
  display: flex;
  align-items: center;
  gap: 5px;
}

.page-number-applications .current-page-applications {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.rows-per-page-applications {
  display: flex;
  align-items: center;
  color: #404040;
  font-size: 15px;
  font-weight: 300;
  font-family: "Inter", sans-serif;
}

.rows-per-page-applications select {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #cccccc;
}
.per-page-export-to-excel-rows-per-page-applications {
  display: flex;
  gap: 15px;
}
.per-page-export-to-excel-applications {
  padding-right: 5px;
  padding-bottom: 5px;
}

.rows-info-applications {
  font-size: 14px;
  color: #666;
}

/* Sidebar Fixed Position */
.sidebar-homepage-allapplications {
  top: 0;
  left: 0;
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  width: 250px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  z-index: 1000; /*stays on top */
  border-top: 1px solid #eee;
}

/* main content shifts to the right to accommodate fixed sidebar */
.main-content-homepage-allapplications {
  margin-left: 250px;
  padding: 20px;
  flex-grow: 1;
  background-color: #f9f9f9;
  overflow-y: scroll; /* For scroller Firefox */
  scrollbar-width: thin;
}

/* Fix the header (for tabs) */
.header-homepage-allapplications {
  top: 0;
  left: 250px;
  position: fixed;
  width: calc(100% - 250px);
  z-index: 100;
  top: 0;
  left: 250px;
  padding: 20px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  border-top: 1px solid #eee;
}

.main-content-homepage-allapplications {
  margin-top: 50px;
}

/* Table Horizontal Scrolling */
.applications-table-allapplications {
  display: block;
  width: 100%;
  overflow-x: auto; /*for horizontal scrolling */
  white-space: nowrap;
  max-height: 500px;
  overflow-y: auto;
  position: relative;
}

.applications-table-allapplications table {
  width: 100%;
  border-collapse: collapse;
}
.applications-table-allapplications {
  scrollbar-width: thin;
  scrollbar-color: #d8d8d8 #ffffff;
}

.applications-table-allapplications::-webkit-scrollbar {
  width: 5px;
}

.applications-table-allapplications::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.applications-table-allapplications::-webkit-scrollbar-thumb {
  background-color: #007bff;
  border-radius: 20px;
  border: 1px solid #0061ca;
}

.applications-table-allapplications::-webkit-scrollbar-thumb:hover {
  background-color: #97afc8; /* Darken scrollbar on hover */
}

/* Keep the header fixed */
.applications-table-allapplications thead {
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
}

/* Fix the first column (Applicant) */
.applications-table-allapplications th:first-child,
.applications-table-allapplications td:first-child {
  position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 2;
}

/* Fix table header (for all columns) */
.applications-table-allapplications th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
  padding: 10px;
  font-weight: bold;
}

/* Fixed header for tabs */
.pipeline-tabs-allapplications {
  position: sticky;
  top: -20px;
  z-index: 50;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.applications-table-allapplications tr:hover {
  background-color: #f1f8ff;
}

/* Restrict width for only 'Applicant' and 'Email' columns */
.applications-table-allapplications th:nth-child(2), /* Applicant */
.applications-table-allapplications th:nth-child(3), /* Email */
.applications-table-allapplications td:nth-child(2), 
.applications-table-allapplications td:nth-child(3) {
  max-width: 180px; /* Restrict width */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; /* Prevent wrapping */
}

/* Show tooltip when hovering over truncated text */
.applications-table-allapplications td:nth-child(2):hover::after,
.applications-table-allapplications td:nth-child(3):hover::after {
  content: attr(data-tooltip);
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  top: 100%;
  left: 0;
  z-index: 100;
}

.form-timing-section-allapplications {
  margin-top: 15px;
  background-color: #f7f7f7;
  margin-bottom: 30px;
}
.email-history-section-allapplications {
  margin-top: 15px;
  background-color: #f7f7f7;
}

.response-item-applications {
  margin-bottom: 10px;
  font-family: "Inter", sans-serif;
}

.response-key-top-allapplications {
  margin-left: 10px;
  font-family: "Inter", sans-serif;
}

.number-box-allapplications {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 1px solid #007bff;
  color: #007bff;
  border-radius: 5px;
  font-size: small;
}

.file-item-allapplications {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  gap: 10px;
  border: none;
  background: none;
  width: 100%;
}
.file-details-allapplications {
  flex-grow: 1;
  display: flex;
  font-family: "Inter", sans-serif;
  flex-direction: column; /* Stack elements vertically */
}

.number-box-allapplications {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 1px solid #007bff;
  color: #007bff;
  border-radius: 5px;
  font-size: small;
}
.documents-section-allapplications {
  margin-top: 20px;
  background-color: #f7f7f7;
  margin-bottom: 50px;
}

.documents-heading-allapplications {
  font-size: 24px;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 500;
  color: #2b2b2b;
  font-family: "Inter", sans-serif;
}

.file-response-allapplications {
  cursor: pointer;
  color: #007bff;
  text-decoration: none;
  font-family: "Inter", sans-serif;
  margin-top: 5px;
}

.file-response-allapplications:hover {
  color: #0061ca;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}
.download-side-file-response-allapplications {
  cursor: pointer;
  color: #666;

  text-decoration: none;
  font-family: "Inter", sans-serif;
  margin-top: 5px;
}

.download-side-file-response-allapplications:hover {
  color: #0061ca;
}

.documents-section-allapplications {
  margin-top: 15px;
  margin-bottom: 30px;
  width: 100%;
}

.round-info-allapplications {
  display: flex;
  gap: 10px;
  margin: 5px 0px 5px 0px;
  font-family: "Inter", sans-serif;
}

.round-name-allapplications {
  padding: 3px 5px;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.2;
  background-color: #ffffff;
  color: #007bff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 1px solid #dadada;
  font-family: "Inter", sans-serif;
}

.round-name-allapplications.active-round {
  background-color: #ffffff;
  color: #0056b3;
  font-weight: 500;
  border: 1px solid #dadada;
}

.round-name-allapplications:hover {
  background-color: #ffffff;
  color: #0056b3;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid #dadada;
}
.custom-button-next-stage-allapplications,
.custom-button-previous-stage-allapplications {
  display: inline-flex;
  align-items: center;
  background-color: #bcdcff;
  color: #000000;
  border: 1px solid #0183ff;
  border-radius: 5px;
  padding: 5px 7px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
  gap: 5px;
}

.custom-button-next-stage-allapplications:hover,
.custom-button-previous-stage-allapplications:hover {
  background-color: #b9dbff;
}

.custom-button-next-stage-allapplications span,
.custom-button-previous-stage-allapplications span {
  display: inline-block;
}

.custom-button-next-stage-allapplications:hover {
  background-color: #28a745;
  color: white;
}

.custom-button-previous-stage-allapplications:hover {
  background-color: #fb96a0;
  color: rgb(0, 0, 0);
}

.button-group-allapplications {
  display: flex;
  gap: 10px;
}

.button-print-response-allapplications {
  color: white;
  background-color: #0183ff;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button-print-response-allapplications:hover {
  color: #007bff;
  background-color: #fff;
  border: 1px solid #0183ff;
  border-radius: 5px;
}

@media print {
  body * {
    visibility: hidden;
  }

  .custom-response-details-applications,
  .custom-response-details-applications * {
    visibility: visible;
  }

  .custom-response-details-applications {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-family: "Arial", sans-serif;
    color: #000;
    line-height: 1.5;
    padding: 20px;
  }

  .print-section {
    display: block !important;
  }

  /*Hide "documents-section", "Form Status Details" and "Email History" in print mode */
  .documents-section-allapplications,
  .form-timing-section-allapplications,
  .email-history-section-allapplications {
    display: none !important;
  }

  .documents-heading-allapplications {
    font-size: 24px;
    text-align: center;
    margin-bottom: 10px;
    font-weight: 500;
    color: #2b2b2b;
    font-family: "Inter", sans-serif;
  }

  .response-item-applications {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }

  .response-key-allapplications {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #9b9b9b;
    font-family: "Inter", sans-serif;
  }

  .response-value-allapplications {
    font-size: 14px;
    color: #343434;
    margin-left: 5px;
    margin-top: 10px;
    font-family: "Inter", sans-serif;
  }

  .number-box-allapplications {
    width: 30px !important;
    height: 25px;
    min-width: 30px;
    border: 1px solid #333;
    border-radius: 5px;
    font-size: 12px;
    text-align: center;
    line-height: 25px;
    margin-right: 10px;
    font-family: "Inter", sans-serif;
  }

  .documents-section-allapplications {
    margin-top: 15px;
    padding: 15px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
  }

  .documents-data-section-allapplications {
    margin-top: 20px;
    background-color: #f7f7f7;
    margin-bottom: 50px;
    padding: 15px;
    border: none;
    background-color: #f9f9f9;
  }

  .file-item-allapplications {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-family: "Inter", sans-serif;
  }

  .file-response-allapplications {
    color: #007bff;
    text-decoration: underline;
    margin-left: 10px;
    font-size: 14px;
  }

  hr {
    border: 0.5px solid #ddd;
    margin: 15px 0;
  }

  /*Hide "Form Status Details" completely */
  .form-timing-section-allapplications {
    display: none !important;
  }

  /* Hide "Email History" completely */
  .email-history-section-allapplications {
    display: none !important;
  }

  .no-response-allapplications {
    color: #535353 !important;
    margin-top: 20px !important;
    text-align: center !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    font-family: "Inter", sans-serif !important;
    display: block !important;
  }
  .download-file-button-applications {
    display: none !important;
  }
}

.status-dropdown-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75%;
}

.status-dropdown-allapplications {
  width: 95px;
  padding: 3px 6px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 300;
  font-family: "Inter", sans-serif;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.status-dropdown-allapplications.pending {
  background-color: #fff9e7;
  color: #b38600;
}

.status-dropdown-allapplications.selected {
  background-color: #e5ffeb;
  color: #00bd2c;
}

.status-dropdown-allapplications.rejected {
  background-color: #fbe2e4;
  color: #c20013;
}

.status-dropdown-allapplications.hold {
  background-color: #def7ff;
  color: #00a5b8;
}

.email-list-allapplications-email-history {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.email-item-allapplications-email-history {
  border: 1px solid #ddd;
  border-radius: 6px;
  background: #fff;
  transition: all 0.3s ease;
  overflow: hidden;
}

.email-header-allapplications-email-history {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 12px;
  font-weight: 600;
  background: #f7f7f7;
  transition: background 0.3s ease;
  border-bottom: 1px solid #ddd;
}

.email-header-allapplications-email-history:hover {
  background-color: #f5faff;
}

.email-header-left-allapplications-email-history {
  display: flex;
  flex-direction: column;
}

.email-subject-allapplications-email-history {
  font-weight: 500;
  color: #4d4d4d;
  font-family: "Inter", sans-serif;
}
.email-subject-allapplications-email-history:hover {
  color: #0183ff;
}

.email-timestamp-allapplications-email-history {
  color: #4d4d4d;
  font-size: 13px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}
.email-timestamp-allapplications-email-history:hover {
  color: #0183ff;
}

.email-body-allapplications-email-history {
  padding: 12px;
  background: #ffffff;
  border-top: 1px solid #ddd;
  font-size: 14px;
  font-weight: 500;
  color: #686868;
  font-family: "Inter", sans-serif;
}

.search-container-allapplications {
  margin-right: 300px;
  z-index: 10;
}
.search-container-allapplications {
  margin-right: 300px;
  z-index: 10;
}

.search-icon-allapplications {
  position: absolute;
  left: 12px;
  font-size: 18px;
  color: #007bff;
  transition: all 0.3s ease-in-out;
}

.search-input-allapplications {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 210px;
  color: #4d4d4d;
  font-size: 13px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.search-input-wrapper-allapplications {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  width: 220px;
}

.search-icon-allapplications {
  position: absolute;
  left: 5px;
  font-size: 16px;
  color: #888;
}

.search-input-allapplications {
  padding: 5px 10px 5px 25px;
  border: none;
  outline: none;
}

/* Style on hover/focus */
.search-input-wrapper-allapplications:hover,
.search-input-allapplications:focus {
  border-color: #007bff;
}

/* Placeholder color */
.search-input-allapplications::placeholder {
  color: #aaa;
}

.button-file-response-allapplications {
  background: transparent;
  border: none;
  text-align: left;
  width: 100%;
  padding: 5px 0;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: #007bff;
  text-decoration: none;
  font-family: "Inter", sans-serif;
}

.button-file-response-allapplications:hover {
  background: transparent;
  color: #0061ca;
}

.download-file-button-applications {
  background-color: #ffffff;
  color: #007bff;
  padding: 4px 8px;
  border: 1px solid #007bff;
  cursor: pointer;
  font-size: 13px;
  font-weight: 300;
  border-radius: 4px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: inline-flex;
  align-items: center;
  gap: 6px;
  text-decoration: none;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 8px;
  margin-bottom: 2px;
  font-family: "Inter", sans-serif;
}

.download-file-button-applications:hover {
  background-color: #ffffff;
  color: #0056b3;
  font-weight: 600;
  transform: translateY(-1px);
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.15);
}

.download-file-button-applications:active {
  background-color: white !important; /* for prevent active color change */
  color: blue !important;
  transform: translateY(1px);
}

.download-file-button-applications::before {
  content: "⬇";
  font-size: 14px;
  font-weight: 600;
}
.date-filter-container-allapplications {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 3px 0px -5px 0px;
}

.date-label-allapplications {
  font-size: 13px;
  color: #404040;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.date-input-allapplications {
  padding: 4px 4px;
  font-size: 13px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: "Inter", sans-serif;
  cursor: pointer;
  transition: border-color 0.3s ease;
  color: #919090;
}

.date-input-allapplications:hover,
.date-input-allapplications:focus {
  border-color: #007bff;
  outline: none;
}

.status-dropdown-container-allapplications {
  position: relative;
  display: inline-block;
}

.status-options-dropdown-allapplications {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px;
  max-height: 250px;
  overflow-y: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1050;
}

.status-option-allapplications {
  cursor: pointer;
  padding: 8px;
  font-size: 15px;
  border-radius: 4px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  color: #5b5b5b;
}

.status-option-allapplications:hover {
  background-color: #dbecfe;
  color: #404040;
}

.filter-by-Status-applications {
  background-color: #ffffff;
  color: #007bff;
  padding: 5px 7px;
  border: 1px solid #82befe;
  cursor: pointer;
  font-size: 13px;
  font-weight: 300;
  border-radius: 4px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 1px;
  margin-bottom: 2px;
  font-family: "Inter", sans-serif;
}

.filter-by-Status-applications:hover {
  background-color: #ffffff;
  color: #0056b3;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.15);
}

.filter-by-Status-applications:active {
  background-color: white !important;
  color: blue !important;
}

.status-count-container-applications {
  position: relative;
  display: inline-block;
  overflow: visible;
}

.status-count-dropdown-applications {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  width: 200px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1050;
  max-height: 250px;
  overflow-y: auto;
}

.status-count-item-applications {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  font-size: 15px;
  border-radius: 4px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  color: #5b5b5b;
}

.status-count-item-applications:hover {
  background-color: #dbecfe;
  color: #404040;
}

.status-count-button-applications {
  background-color: #ffffff;
  color: #007bff;
  padding: 5px 3px;
  border: 1px solid #82befe;
  cursor: pointer;
  font-size: 13px;
  font-weight: 300;
  border-radius: 4px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 8px;
  margin-bottom: 2px;
  font-family: "Inter", sans-serif;
}

.status-count-button-applications:hover {
  background-color: #ffffff;
  color: #0056b3;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.15);
}


